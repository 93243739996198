import * as Sentry from '@sentry/react';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Settings } from 'luxon';
import { Provider } from 'react-redux';
import configureAppStore from './store';
import { getCookie } from './api';

// Configure Sentry in deployed envs
if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: DJ_CONST.SENTRY_DSN,
        environment: DJ_CONST.SENTRY_ENVIRONMENT,
    });

    // handle rejected promises
    window.addEventListener('unhandledrejection', (evt) => {
        Sentry.captureException(evt.reason);
    });
    // If we have authenticated user, pass its data on to Sentry
    if (DJ_CONST.user) {
        Sentry.configureScope((scope) => {
            scope.setUser({
                id: DJ_CONST.user.id,
                email: DJ_CONST.user.email,
            });
            Sentry.setContext('name', DJ_CONST.user.name);
        });
    }
}

// Create Redux store
const store = configureAppStore({});

// Luxon time default language setup
const languageCookie = getCookie('django_language');
let language;
switch (languageCookie) {
    case 'et':
        language = 'et-EE';
        break;
    case 'en':
        language = 'en-GB';
        break;
    default:
        language = 'en-GB';
        break;
}
Settings.defaultLocale = language;

// TanStack Query setup
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 1,
            refetchOnWindowFocus: false,
        },
    },
});

async function initNavigationBar() {
    const elem = document.getElementById('navigation-bar');
    if (!elem) {
        return;
    }

    const NavigationBarComponent = await lazy(() =>
        import('./components/NavigationBar/NavigationBar'),
    );

    if (!NavigationBarComponent) {
        return;
    }

    ReactDOM.render(
        <Provider store={store}>
            <Suspense fallback={<div />}>
                <NavigationBarComponent />
            </Suspense>
        </Provider>,
        elem,
    );
}

async function initSideBar() {
    const elem = document.getElementById('side-bar');
    if (!elem) {
        return;
    }

    const SideBarComponent = await lazy(() =>
        import('./components/SideBar/SideBar'),
    );

    if (!SideBarComponent) {
        return;
    }

    ReactDOM.render(
        <Provider store={store}>
            <Suspense fallback={<div />}>
                <SideBarComponent />
            </Suspense>
        </Provider>,
        elem,
    );
}

async function overviewComponent(componentName) {
    const elem = document.getElementById('overview-component');
    if (!elem) {
        return;
    }

    let OverviewComponent;
    switch (componentName) {
        case 'dashboard':
            OverviewComponent = await lazy(() =>
                import('./components/Dashboard/Dashboard'),
            );
            break;
        case 'info':
            OverviewComponent = await lazy(() =>
                import('./components/Info/Info'),
            );
            break;
        case 'floors':
            OverviewComponent = await lazy(() =>
                import('./components/Floor/Floors'),
            );
            break;
        case 'tagConstraints':
            OverviewComponent = await lazy(() =>
                import(
                    './components/ConstraintModalities/ConstraintModalities'
                ),
            );
            break;
        case 'organizations':
            OverviewComponent = await lazy(() =>
                import('./components/Organization/Organizations'),
            );
            break;
        case 'buildings':
            OverviewComponent = await lazy(() =>
                import('./components/Buildings/Buildings'),
            );
            break;
        case 'users':
            OverviewComponent = await lazy(() =>
                import('./components/User/Users'),
            );
            break;
        case 'tags':
            OverviewComponent = await lazy(() =>
                import('./components/Tag/Tags'),
            );
            break;
        case 'rooms':
            OverviewComponent = await lazy(() =>
                import('./components/Room/Rooms'),
            );
            break;
        case 'devices':
            OverviewComponent = await lazy(() =>
                import('./components/Device/Devices'),
            );
            break;
        case 'zones':
            OverviewComponent = await lazy(() =>
                import('./components/Zone/Zones'),
            );
            break;
        case 'areas':
            OverviewComponent = await lazy(() =>
                import('./components/Area/Areas'),
            );
            break;
        case 'constraintRelations':
            OverviewComponent = await lazy(() =>
                import('./components/ConstraintRelations/ConstraintRelations'),
            );
            break;
        case 'groups':
            OverviewComponent = await lazy(() =>
                import('./components/Group/Groups'),
            );
            break;
        case 'user_log':
            OverviewComponent = await lazy(() =>
                import('./components/Logs/Log'),
            );
            break;
        case 'deviceMonitoring':
            OverviewComponent = await lazy(() =>
                import('./components/DeviceMonitoring/DeviceMonitoring'),
            );
            break;
        default:
            OverviewComponent = undefined;
    }

    if (!OverviewComponent) {
        return;
    }

    const FooterComponent = await lazy(() =>
        import('./components/Footer/Footer'),
    );

    if (!FooterComponent) {
        return;
    }

    ReactDOM.render(
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <Suspense fallback={<div />}>
                    <OverviewComponent />
                    <FooterComponent />
                </Suspense>
            </QueryClientProvider>
        </Provider>,
        elem,
    );
}

async function detailComponent(componentName) {
    const elem = document.getElementById('detail-component');
    if (!elem) {
        return;
    }
    let DetailComponent;
    switch (componentName) {
        case 'buildingDetail':
            DetailComponent = await lazy(() =>
                import('./components/Buildings/Detail/BuildingsDetail'),
            );
            break;
        case 'tagDetail':
            DetailComponent = await lazy(() =>
                import('./components/Tag/Detail/TagDetail'),
            );
            break;
        case 'userDetail':
            DetailComponent = await lazy(() =>
                import('./components/User/Detail/UsersDetail'),
            );
            break;
        default:
            DetailComponent = undefined;
    }

    if (!DetailComponent) {
        return;
    }

    const FooterComponent = await lazy(() =>
        import('./components/Footer/Footer'),
    );

    if (!FooterComponent) {
        return;
    }

    ReactDOM.render(
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <Suspense fallback={<div />}>
                    <DetailComponent />
                    <FooterComponent />
                </Suspense>
            </QueryClientProvider>
        </Provider>,
        elem,
    );
}

async function addEditComponent(componentName) {
    const elem = document.getElementById('add-edit-component');
    if (!elem) {
        return;
    }

    let AddEditComponent;
    switch (componentName) {
        case 'organizationAdd':
            AddEditComponent = await lazy(() =>
                import(
                    './components/Organization/AddEditOrganization/OrganizationAdd'
                ),
            );
            break;
        case 'organizationEdit':
            AddEditComponent = await lazy(() =>
                import(
                    './components/Organization/AddEditOrganization/OrganizationEdit'
                ),
            );
            break;
        case 'buildingAdd':
            AddEditComponent = await lazy(() =>
                import('./components/Buildings/AddEditBuilding/BuildingAdd'),
            );
            break;
        case 'buildingEdit':
            AddEditComponent = await lazy(() =>
                import('./components/Buildings/AddEditBuilding/BuildingEdit'),
            );
            break;
        case 'floorAdd':
            AddEditComponent = await lazy(() =>
                import('./components/Floor/AddEditFloor/FloorAdd'),
            );
            break;
        case 'floorEdit':
            AddEditComponent = await lazy(() =>
                import('./components/Floor/AddEditFloor/FloorEdit'),
            );
            break;
        case 'roomAdd':
            AddEditComponent = await lazy(() =>
                import('./components/Room/AddEditRoom/RoomAdd'),
            );
            break;
        case 'roomEdit':
            AddEditComponent = await lazy(() =>
                import('./components/Room/AddEditRoom/RoomEdit'),
            );
            break;
        case 'tagAdd':
            AddEditComponent = await lazy(() =>
                import('./components/Tag/AddEditTag/TagAdd'),
            );
            break;
        case 'tagEdit':
            AddEditComponent = await lazy(() =>
                import('./components/Tag/AddEditTag/TagEdit'),
            );
            break;
        case 'deviceAdd':
            AddEditComponent = await lazy(() =>
                import('./components/Device/AddEditDevice/DeviceAdd'),
            );
            break;
        case 'deviceEdit':
            AddEditComponent = await lazy(() =>
                import('./components/Device/AddEditDevice/DeviceEdit'),
            );
            break;
        case 'zoneAdd':
            AddEditComponent = await lazy(() =>
                import('./components/Zone/AddEditZone/ZoneAdd'),
            );
            break;
        case 'zoneEdit':
            AddEditComponent = await lazy(() =>
                import('./components/Zone/AddEditZone/ZoneEdit'),
            );
            break;
        case 'areaAdd':
            AddEditComponent = await lazy(() =>
                import('./components/Area/AddEditArea/AreaAdd'),
            );
            break;
        case 'areaEdit':
            AddEditComponent = await lazy(() =>
                import('./components/Area/AddEditArea/AreaEdit'),
            );
            break;
        case 'userAdd':
            AddEditComponent = await lazy(() =>
                import('./components/User/AddEditUser/UserAdd'),
            );
            break;
        case 'userEdit':
            AddEditComponent = await lazy(() =>
                import('./components/User/AddEditUser/UserEdit'),
            );
            break;
        case 'groupAdd':
            AddEditComponent = await lazy(() =>
                import('./components/Group/AddEditGroup/GroupAdd'),
            );
            break;
        case 'groupEdit':
            AddEditComponent = await lazy(() =>
                import('./components/Group/AddEditGroup/GroupEdit'),
            );
            break;

        case 'ConstraintAdd':
            AddEditComponent = await lazy(() =>
                import(
                    './components/ConstraintModalities/AddEditConstraint/ConstraintAdd'
                ),
            );
            break;
        case 'ConstraintEdit':
            AddEditComponent = await lazy(() =>
                import(
                    './components/ConstraintModalities/AddEditConstraint/ConstraintEdit'
                ),
            );
            break;
        case 'constraintRelationsAdd':
            AddEditComponent = await lazy(() =>
                import(
                    './components/ConstraintRelations/AddEditConstraintRelations/ConstraintRelationsAdd'
                ),
            );
            break;
        case 'constraintRelationsEdit':
            AddEditComponent = await lazy(() =>
                import(
                    './components/ConstraintRelations/AddEditConstraintRelations/ConstraintRelationsEdit'
                ),
            );
            break;

        default:
            AddEditComponent = undefined;
    }

    if (!AddEditComponent) {
        return;
    }

    const FooterComponent = await lazy(() =>
        import('./components/Footer/Footer'),
    );

    if (!FooterComponent) {
        return;
    }

    ReactDOM.render(
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <Suspense fallback={<div />}>
                    <AddEditComponent />
                    <FooterComponent />
                </Suspense>
            </QueryClientProvider>
        </Provider>,
        elem,
    );
}

async function compareComponent(componentName) {
    const elem = document.getElementById('compare-component');
    if (!elem) {
        return;
    }
    let CompareComponent;
    switch (componentName) {
        case 'tagCompare':
            CompareComponent = await lazy(() =>
                import('./components/Tag/Detail/TagCompare'),
            );
            break;
        default:
            CompareComponent = undefined;
    }

    if (!CompareComponent) {
        return;
    }

    const FooterComponent = await lazy(() =>
        import('./components/Footer/Footer'),
    );

    if (!FooterComponent) {
        return;
    }

    ReactDOM.render(
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <Suspense fallback={<div />}>
                    <CompareComponent />
                    <FooterComponent />
                </Suspense>
            </QueryClientProvider>
        </Provider>,
        elem,
    );
}

async function dataOrderComponent(name) {
    const elem = document.getElementById('data-order');
    if (!elem) {
        return;
    }
    const DataOrderComponent = await lazy(() =>
        import('./components/DataOrder/DataOrder'),
    );

    if (!DataOrderComponent) {
        return;
    }

    const FooterComponent = await lazy(() =>
        import('./components/Footer/Footer'),
    );

    if (!FooterComponent) {
        return;
    }

    ReactDOM.render(
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <Suspense fallback={<div />}>
                    <DataOrderComponent name={name} />
                    <FooterComponent />
                </Suspense>
            </QueryClientProvider>
        </Provider>,
        elem,
    );
}

export {
    initNavigationBar,
    initSideBar,
    overviewComponent,
    addEditComponent,
    detailComponent,
    dataOrderComponent,
    compareComponent,
};
